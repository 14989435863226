<template>
  <div>
    <button
      type="button"
      class="btn btn-success font-weight-bold btn-block"
      @click.prevent="abrir"
    >
      Criar Conta
    </button>

    <b-modal
      ref="mdlCriarConta"
      title="Criar Conta do Atende"
      @hidden="limpar"
      @shown="validacao"
      @ok="salvar"
      ok-title="Salvar"
      ok-variant="success"
      cancel-title="Cancelar"
      size="lg"
      scrollable
      ignore-enforce-focus-selector=".swal2-container"
    >
      <b-form ref="frmCriarConta">
        <h6>DADOS DA CONTA</h6>
        <div class="separator separator-solid mb-3"></div>

        <div class="row">
          <div class="col-12">
            <b-form-group>
              <label>Nome <span class="text-danger">*</span>:</label>
              <b-form-input
                name="contaNome"
                v-model="frmCriarConta.conta.nome"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <b-form-group>
              <label
                >Qtd. Máx. Caixa de Entrada
                <span class="text-danger">*</span>:</label
              >
              <b-form-input
                name="contaQtdMaxCaixaEntrada"
                v-model="frmCriarConta.conta.qtdMaxCaixaEntrada"
                data-inputmask="'alias': 'integer'"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-4">
            <b-form-group>
              <label
                >Qtd. Máx. Usuários <span class="text-danger">*</span>:</label
              >
              <b-form-input
                name="contaQtdMaxUsuarios"
                v-model="frmCriarConta.conta.qtdMaxUsuarios"
                data-inputmask="'alias': 'integer'"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex">
          <p>
            Criar caixa de entrada padrão <span class="text-danger">*</span>:
          </p>
          <InputSwitch
            v-model="frmCriarConta.conta.criarCaixa"
            style="margin-left: 10px"
          ></InputSwitch>
        </div>

        <h6>DADOS DO USUÁRIO ADMINISTRADOR</h6>
        <div class="separator separator-solid mb-3"></div>

        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label>Nome <span class="text-danger">*</span>:</label>
              <b-form-input
                name="usuarioNome"
                v-model="frmCriarConta.usuario.nome"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label>Email <span class="text-danger">*</span>:</label>
              <b-form-input
                name="usuarioEmail"
                v-model="frmCriarConta.usuario.email"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label>Senha <span class="text-danger">*</span>:</label>
              <b-form-input
                v-model="frmCriarConta.usuario.senha"
                name="usuarioSenha"
                type="password"
                autocomplete="new-password"
              ></b-form-input>
              <span class="form-text text-muted"
                >A senha deve conter letras maiúsculas, minúsculas, número e um
                caractere especial.</span
              >
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label>Confirmar Senha <span class="text-danger">*</span>:</label>
              <b-form-input
                v-model="frmCriarConta.usuario.confirmarSenha"
                name="usuarioConfirmarSenha"
                type="password"
                autocomplete="new-password"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-12">
            <gerador-senha
              @usar-senha="usarSenha"
              :comprimento="10"
              :simbolos="true"
            ></gerador-senha>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import InputSwitch from "primevue/inputswitch";
import Inputmask from "inputmask";

export default {
  name: "modal-criar-conta-live-chat",
  props: {
    idConta: {
      type: Number,
      default: null
    }
  },
  components: {
    InputSwitch
  },

  data() {
    return {
      frmCriarConta: {
        idConta: null,
        conta: {
          nome: "",
          qtdMaxCaixaEntrada: "",
          qtdMaxUsuarios: "",
          criarCaixa: false
        },
        usuario: {
          nome: "",
          email: "",
          senha: "",
          confirmarSenha: ""
        }
      }
    };
  },

  computed: {
    ...mapGetters(["validatorErrors"])
  },

  methods: {
    limpar() {
      this.frmCriarConta.idConta = null;
      this.frmCriarConta.conta.nome = "";
      this.frmCriarConta.conta.qtdMaxCaixaEntrada = "";
      this.frmCriarConta.conta.qtdMaxUsuarios = "";
      this.frmCriarConta.conta.criarCaixa = false;
      this.frmCriarConta.usuario.nome = "";
      this.frmCriarConta.usuario.email = "";
      this.frmCriarConta.usuario.senha = "";
      this.frmCriarConta.usuario.confirmarSenha = "";
    },

    abrir() {
      this.limpar();

      this.frmCriarConta.idConta = this.idConta;

      this.$refs["mdlCriarConta"].show();
    },

    validacao() {
      const frmCriarConta = this.$refs["frmCriarConta"];

      Inputmask().mask(frmCriarConta.querySelectorAll("input"));

      this.fv = formValidation(frmCriarConta, {
        fields: {
          contaNome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          contaQtdMaxCaixaEntrada: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          contaQtdMaxUsuarios: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          usuarioNome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          usuarioEmail: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              emailAddress: {
                message: "O email informado é inválido."
              },
              blank: {}
            }
          },
          usuarioSenha: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              stringLength: {
                min: 8,
                message:
                  "A senha deve ter no mínimo 8 caracteres e conter letras maiúsculas, minúsculas, número e um caractere especial."
              },
              regexp: {
                regexp:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                message:
                  "A senha deve ter no mínimo 8 caracteres e conter letras maiúsculas, minúsculas, número e um caractere especial."
              },
              blank: {}
            }
          },
          usuarioConfirmarSenha: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              identical: {
                message:
                  'O campo "Senha" e "Confirmar Senha" devem ser iguais.',
                compare: function () {
                  return this.frmCriarConta.senha;
                }
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("conta/live-chat/criar-conta", this.frmCriarConta)
            .then((response) => {
              const conta = response.data.data;
              this.$emit("conta-criada", conta);
              this.$refs["mdlCriarConta"].hide();

              Swal.fire({
                title: "MENSAGEM",
                text: "Conta criada com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    usarSenha(senha) {
      this.frmCriarConta.usuario.senha = senha;
      this.frmCriarConta.usuario.confirmarSenha = senha;
    }
  }
};
</script>
